* {
  font-family: 'Lato';
  letter-spacing: 0.5px;
  color: #333;
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
}

input:focus {
  outline:none;
}

a {
  text-decoration: none;
  color: #111;
}

a:hover {
  border-bottom: 1px solid rgb(161, 245, 65);
}

sup, sub {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}
sub { 
  top: 0.4em; 
}