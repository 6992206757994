.offer-page-container {
  padding: 60px;
}

.offer-page-inner-container {
  display: flex;
  width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

.top-intro {
  margin-top: 80;
  width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

.top-hi {
  font-size: 36px;
  font-weight: bold;
}

.top-subtitle {
  font-size: 24px;
  margin-top: 32px;
  width: 650px;
  line-height: 32px;
}

.your-impact {
  padding: 20px;
  margin-right: 40px;
  margin-left: 20px;
}

.top-box-fnz-points {
  padding: 20px;
  background-color: #F6F7FC;
  border-radius: 20px;
  text-align: center;
  margin-right: 10px;
}

.top-box-co2-balance {
  padding: 20px;
  background-color: #F6F7FC;
  border-radius: 20px;
  text-align: center;
}

.trip-details {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

.offer-panel-container {
  width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

.trip-details-column {
  width: 50%;
}

.left-detail-column {
  width: 150px;
}

.right-detail-column {
  font-weight: bold;
}

.agency-person-name, .agency-person-phone {
  display: inline;
  margin-right: 5px;
}

.top-box-container {
  display: flex;
}

@media only screen and (max-width: 1000px) {
  body {
    max-width: 100%;
    width: 100%;
  }
  .offer-page-container {
    padding: 20px;
  }  
  .offer-page-inner-container {
    width: 100%;
    display: block;
  }
  .leaf {
    display: none;
  }
  .your-impact {
    display: none;
  }
  .top-intro {
    width: 100%;
  }
  .top-hi {
    font-size: 26px;
    margin-bottom: -20px;
    margin-top: 20px;
  }
  .top-subtitle {
    font-size: 19px;
    width: 100% !important;
  }
  .trip-details {
    width: 100%;
    display: block;
    margin-top: 20px;
    margin-bottom: 30px;    
  }
  .offer-panel-container {
    width: 100% !important;
  }
  .trip-details-column {
    width: 100%;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .left-detail-column {
    width: 50%;
  }
  .right-detail-column {
    width: 50%;
  }
  .agency-person-name, .agency-person-phone {
    display: block;
  }
  .top-box-fnz-points {
    width: 50%;
  }
  .top-box-co2-balance {
    width: 50%;
  }
  .top-box-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 30px;
  }
}