.button-container {
  display: inline-block;
}

.confirm-button {
  border:none;
  font-size: 16px;
  font-weight: bold;
  height: 48px;
  width: 234px;
  border-radius: 24px;
  background: linear-gradient(206.57deg, #3F55F4 0%, #0A1EAA 100%);
  color: white;
  opacity: 0.95;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirm-button:hover {
  opacity: 1;
}

.full-width-container .confirm-button {
  width: 100% !important;
  display: block;
}

.full-width-container {
  width: 100% !important;
  display: block;
}