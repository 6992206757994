.radio-button-component {
  border-radius: 30px;
  width: 32px;
  height: 32px;
  background-color: white;
  color: #333;
  box-shadow: 0px 0px 6px #999;
  cursor: pointer;
  text-align: center;
  line-height: 32px;
}

.radio-button-component:hover {
  box-shadow: 0px 0px 6px #999;
}

.radio-selection-icon {
  position: relative;
  left: 5px;
}

.radio-selection-icon > path {
  color: white;
}

.radio-button-off {
  background-color: white;
  box-shadow: 0px 0px 2px #999;  
}