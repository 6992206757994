.footer-title {
  font-size: 36px;
  line-height: 45px;
}

.footer-container {
  background-color: #F6F7FC;
  width: 100%;
  padding: 50px;
  padding-top: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  border-top: 1px solid #ddd;
}

.footer-columns {
  display: flex;
  width: 100%;
}

.footer-columns-inner-left {
  margin-top: 64px;
  max-width: 480px;
  margin-right: 10px;
}

.footer-columns-inner-right {
  margin-top: 64px;
  max-width: 480px;
  margin-left: 10px;
}

.leaf-traveler {
  position: relative;
  margin-top: -290px;
}

.sub-slogan {
  max-width: 1180px;
  padding: 40px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.bottom-links {
  margin-top: 70px;
  display: flex;
  font-size: 14px;
}

.bottom-link {
  margin-right: 40px;
}

.footer-sub-container {
  background-color: white;
  width: 100%;
  padding: 50px;
  padding-top: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.bottom-spacer-to-graphic {
  flex-grow: 1;
}

@media only screen and (max-width: 1000px) {
  .footer-container {
    padding: 0px;
  }

  .footer-title {
    font-size: 26px;
    line-height: 35px;
  }

  .footer-columns {
    display: block;
  }

  .footer-columns-inner-left {
    margin:0px;
    margin-top:30px;
  }

  .footer-columns-inner-right {
    margin:0px;
    margin-top:30px;
  }

  .leaf-traveler {
    display: none;
  }

  .sub-slogan {
    padding: 0px;
    text-align: center;
  }

  .bottom-links {
    margin-top:40px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
  }

  .bottom-link {
    margin-right: 5px;
  }

  .footer-sub-container {
    padding: 15px;
    text-align: center;
  }

  .bottom-spacer-to-graphic {
    display: none;
  }
}